
/*--------------------------------
    Single Demo  
-------------------------------*/
.single-demo {
    text-align: center;
    background: var(--color-lessdark);
    border-radius: 10px;
    padding: 20px;
    a {
        display: block;
    }
    .thumbnail {
        img {
            border-radius: 10px;
            width: 100%;
        }
    }
    .title {
        margin: 0;
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.5px;
    }
}


